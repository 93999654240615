<template>
  <div class="page-profile common-page">
    <div class="page-header fx-vb">
      <div class="btn-act back" @click="pageBack"></div>
      <h1 class="page-title"></h1>
      <div class="btn-act"></div>
    </div>
    <div class="page-con">
      <div class="user-detail fx-vb">
        <div class="avatar">
          <!-- <img loading="lazy" :src="userData.avatar" alt=""> -->
          <img loading="lazy" src="@/assets/images/avatar-placeholder.png" alt="" />
        </div>
        <div class="detail">
          <div class="name">
            {{ userData.name }}
          </div>
          <div class="id">
            {{ userData.fb_id }}
          </div>
        </div>
        <div class="btn-logout fx-vc" @click="logout">Log out</div>
      </div>
      <div class="vip-box">
        <div class="vip-con" v-if="userData.is_vip">
          <h3>VIP activated</h3>
          <p>expires on {{ userData.vip_expired_at | dayFormat }}</p>
        </div>
        <div class="free-con" v-else>
          <h3>Unlimit Reading VIP</h3>
        </div>
      </div>
      <coins-box></coins-box>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { apiLogout } from "@/api";
import Cookies from "js-cookie";
import { dayFormat } from "@/util";
import CoinsBox from "@/components/coinsList";

export default {
  name: "BookList",
  data() {
    return {};
  },
  components: {
    CoinsBox,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
    }),
  },
  filters: {
    dayFormat,
  },
  methods: {
    pageBack() {
      this.$router.push({
        name: "HomePage",
      });
    },
    async logout() {
      try {
        this.$toast.loading({
        duration: 0
      })
        await apiLogout();
      } catch (error) {
        console.log(error);
      }
      this.$toast.clear();
      Cookies.remove('atoken');
      Cookies.remove('rtoken');
      Cookies.remove('uid');
      window.location.href = '/'
    }
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
};
</script>

<style lang="less">
@import url("./index.less");
</style>
